.App {
  font-family: Muli,sans-serif;
  width: 100%;
  bottom: 0px;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background: #5648c7 url('/kino.png') 16px 0 no-repeat;
  background-size: contain;
  height: 48px;
  font-size: 24px;
  color: white;
  display: flex;
  position: relative;
  z-index: 9999;
  flex-wrap: wrap;
}

.App-header .logo {
    padding: 8px 0 0px 70px;
    display: inline-block;
}

.App-header .logo a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.App-header .alpha {
  background: white;
  padding: 2px 3px;
  border-radius: 3px;
  font-size: 12px;
  color: #5648c7;
  margin-left: 10px;
}

.App-header .desc {
  font-size: 14px;
  display: block;
}

.App-header .Nav-right li:hover {
  color: #FFF;
  text-decoration: none;
  background: rgba(255,255,255,0.1);
}

.App-header .Nav-right li a {
  padding: 19px 12px;
  display: inline-block;
  font-size: 20px;
  float: left;
}

.App-header .Nav-right li a {
  color: #FFF;
  text-decoration: none;
}

.App-intro {
  font-size: large;
}

.tools a {
  display: inline-block;
  justify-content: center;
  padding: 4px;
  margin: 8px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* Generic Styles */
.right {
  float: right;
}
.left {
  float: left;
}

a {
  color: #5648c7;
}

.btn {
  padding: 4px 8px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  box-shadow: inset 0 1px 0 rgba(248, 249, 250, 0.0125), 0 1px 1px rgba(33, 37, 41, 0.05);
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 1px 1px 3px 1px;
  font-size: 14px;
  display: inline-block;
  line-height: normal;
  line-height: initial;
  align-self: center;
  text-decoration: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.btn:hover {
  border-width: 1px 1px 1px 1px;
  margin-bottom: 2px;
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.bg-red {
  background-color: #fa5252;
}

.bg-blue {
  background-color: #42a5f5;
}

.bg-green {
  background-color: #37a228;
}

.bg-purple {
  background-color: #7465e4;
}

.text-white {
  color: #FFF;
}

body > div:not(.tippy-popper){ /* kms, this freaking rule. :< */
  z-index: 10000;
}

.tippy-popper input {
  border: 0px none transparent;
  font-size: 18px;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
}

.canvas-characters, .canvas-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App .notification-container {
  right: calc(1em + 30px);
  overflow: visible;
  width: 230px;
}

.App .notification {
  padding: 15px 8px;
}

.notification-container .notification:before {
  content: ' ';
  border-top: calc(1em + 5.5px) solid transparent;
  border-bottom: calc(1em + 5.5px) solid transparent;
  border-right: calc(1em + 5.5px) solid white;
  width: 0;
  height: 0;
  position: absolute;
  left: calc(-1em - 5.5px);
  top: 15px;
}

.notification-container .notification:after {
  content: ' ';
  border-top: calc(1em + 5.5px) solid transparent;
  border-bottom: calc(1em + 5.5px) solid transparent;
  border-left: calc(1em + 5.5px) solid white;
  width: 0;
  height: 0;
  position: absolute;
  right: calc(-1em - 5.5px);
  top: 1px;
  font-size: 28px;
}

.notification-container .notification.notification-warning:after, .notification-container .notification.notification-warning:before {
  border-left-color: #f89406;
  border-right-color: #f89406;
}

.notification-container .notification.notification-error:after, .notification-container .notification.notification-error:before {
  border-left-color: #bd362f;
  border-right-color: #bd362f;
}

.map-select-container {
  width: 374px;
  padding: 13px 0;
  font-size: 16px;
  position: relative;
  z-index: 10001;
}

.map-select-container .VirtualizedSelectOption {
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 35px;
  padding: 0 4px;
  cursor: pointer;
}

.map-select-container .VirtualizedSelectOption:hover {
  background: #eee;
}

.bg-color-picker {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.bg-color-grid {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.bg-color-picker-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bg-color-picker-container .sketch-picker {
  position: absolute;
  top: 44px;
  left: 8px;
  z-index: 10002;
}

.settings-container {
  display: flex;
  flex-direction: column;
}

.settings-container > label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.settings-container > label > *:first-child {
  margin-right: 8px;
}

.settings-container input {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  font-size: 14px;
  border-radius: 2px;
}

.settings-container select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  border-radius: 2px;
}

.settings-container a {
  text-decoration: none;
}

.settings-container .animation-play {
  margin-left: 16px;
  cursor: pointer;
}

.settings-cog {
  width: 48px;
}

.settings-cog > div {
  width: 100%;
  height: 100%;
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.map-select-container .Select-menu {
  max-height: 398px;
}

.map-select-container .Select-menu-outer {
  max-height: 400px;
}

.App-header .Nav-right li a.patreon-link {
  color: hotpink;
  font-weight: 800;
  text-shadow: 0 0 4px red;
}

@media (max-width: 767px) {
  .App-header {
    justify-content: flex-end;
  }

  .App-header .logo {
    display: none;
  }

  .App-header .Nav-right {
    align-self: center;
    display: inline-flex;
    list-style: none;
    height: 100%;
    margin: 0;
  }
  
  .App-header .Nav-right li {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .App-header {
    justify-content: space-between;
  }

  .App-header .logo {
    display: inline;
  }

  .App-header .Nav-right {
    align-self: center;
    padding: 0px 10px 0px 0;
    display: inline-flex;
    list-style: none;
    height: 100%;
    margin: 0;
  }
  
  .App-header .Nav-right li {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 0 8px;
  }
}
.item-listing {
  position: absolute;
  left: 0;
  top: 48px;
  bottom: 100px;
  max-width: 285px;
  width: 100%;
  background: #FFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3);
  z-index: 2;
}

.item-listing-header {
  font-size: 18px;
  line-height: 36px;
  min-height: 36px;
  padding: 0 4px 0 8px;
  display: flex;
  justify-content: space-between;
  color: #3F3F3F;
}

.item-listing-header input[type=search] {
  align-self: center;
}

.item-listing-content {
  display: flex;
  flex-grow: 1;
  max-height: calc(100% - 36px);
  flex-direction: column;
}

.item-listing-categories {
  display: flex;
  flex-direction: column;
  border-top: solid 1px white;
  background-color: #5648c7;
  padding: 0.5rem 0 0;
}

.item-listing-categories select {
  border: 0px;
  font-size: 14px;
  line-height: 28px;
  min-height: 28px;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  padding: 0 4px;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0 0.5rem 0.5rem;
}

.item-listing-icons {
  flex: 1 1;
}

.similar-items {
  position: absolute;
  z-index: 3;
  padding: 4px;
  background: #eee;
  padding: 5px;
  box-shadow: 0 0 1px #999;
  border-radius: 5px;
  cursor: pointer;
}

.similar-items div {
  display: inline-block;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
  margin: 0 2px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.similar-items div:hover {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.item-img-container {
  display: inline-block;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
}

.item-img-container img, .similar-items img {
  max-width: 32px;
  overflow: hidden;
}

.item-search {
  border: 0;
  font-size: 14px;
  line-height: 28px;
  min-height: 28px;
  border-radius: 0.25rem;
  padding: 0 8px;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.filters {
  font-size: 14px;
  line-height: 36px;
  min-height: 36px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
}

.filters label {
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  font-weight: 700;
}

.gender-select {
  border: 0px;
  font-size: 14px;
  line-height: 28px;
  min-height: 28px;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  padding: 0 4px;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.9);
  width: 5rem;
}

.items-footer {
  display: flex;
  align-items: center;
  background-color: #5648c7;
  color: white;
  justify-content: space-between;
}

.items-footer label {
    display: flex;
    margin: 0 0.5rem 0 0.25rem;
}

.item-img-container {
  cursor: pointer;
  padding: 0.25rem;
  text-align: center;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}

.item-img-container:hover {
  background-color: #e2e2e2;
  border-radius: 0.25rem;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.equipped-items {
  position: fixed;
  right: 0.5rem;
  bottom: 115px;
  width: 15%;
  background: rgba(255, 255, 255, 0.75);
  min-width: 255px;
  overflow: auto;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
}

.equipped-items a {
  text-decoration: none;
}

.equipped-items-header {
  min-height: 36px;
  font-size: 18px;
  line-height: 36px;
  padding: 0 16px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  color: #3F3F3F;
  display: flex;
  justify-content: space-between;
}

.equipped-items-title {
  font-weight: bold;
}

.equipped-items-header .equipped-items-title {
  align-self: center;
}

.equipped-items-listing {
  flex-grow: 1;
  max-height: 40vh;
  overflow: auto;
}

.download-bar {
  text-align: right;
}

.report-issues, .download-bar {
  display: flex;
  padding: 8px;
}

.download-bar.bg-blue:hover {
  opacity: 0.8;
  background: #42a5f5;
}

.download-bar.bg-red:hover {
  opacity: 0.8;
  background: #fb6d6d;
}

.equipped-items-item {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  position: relative;
  overflow: visible;
}

.equipped-items-item .hue-picker {
  position: absolute!important;
  bottom: -6px;
  left: 0;
  right: 0;
}

.equipped-items-item .hue-picker:not(:focus) {
  display: none;
}

.equipped-items-item:hover .hue-picker {
  display: block;
}

.equipped-items-item:hover {
  background: #F5F5F5;
}

.equipped-items-item img {
  align-self: center;
  min-width: auto;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
}

.equipped-items-item-meta {
  padding: 0 0 0 8px;
  flex-grow: 1;
}

.equipped-items-item-meta-category {
  font-size: 12px;
}

.pdn-button .download-bar, .pdn-button {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
}

.customizing-item {
  width: 220px;
  position: relative;
  max-height: 40vh;
  overflow: auto;
  padding: 10px;
}

.customizing-item {
  display: flex;
  flex-direction: column;
}

.customizing-item > label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.customizing-item > span {
  text-align: left;
  display: inline-block;
  width: 100%;
  line-height: 24px;
}

.customizing-item > span > span {
  margin-left: 4px;
  font-size: 16px;
}

.customizing-item > span > span.flex {
  justify-content: space-between;
}

.customizing-item > span > span > input {
  margin-left: 32px;
  display: flex;
  max-width: 50%;
  text-align: right;
}

.item-property {
  margin: 8px 0;
}

.item-islot, .item-vslot {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}
.add-possible {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.add-title {
  border-bottom: 2px solid rgba(0,0,0,0.2);
}

.add-possible li {
  padding: 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.add-possible li.clickable {
  cursor: pointer;
  border-radius: 4px;
}

.add-possible li.clickable:hover {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
}

.character-list {
    position: fixed;
    right: 0;
    bottom: 0px;
    height: 100px;
    width: 100%;
    background: rgba(255,255,255,0.75);
    z-index: 2;
    box-shadow: 0 -1px 1px 1px rgba(0,0,0,0.14);
}

.character-list > div {
  width: 100%;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  height: 100px;
}

.character-list > div > div {
  width: 100px;
  height: 100px;
}

.character-list div.character,
.character-list div.pet,
.character-list div.npc,
.character-list div.mob,
.character-list .add {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.character-list .add span {
  font-size: 42px;
  width: 70px;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  line-height: 65px; /* It looks off center if the line height is 70px :( */
}

.character-list .add:hover span {
  background: rgba(0,0,0, 0.1);
}

.character-list div.character:not(:hover) > * {
    display: none;
}

.character-list div.active {
  box-shadow: inset 0 0 32px rgba(86, 72, 199, 0.25);
}

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
  }

  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px #0099E0;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0px 0px 5px 5px #0099E0;
  }

  .bold {
    font-weight: bold;
  }

.character-customizeable-options {
  display: flex;
  flex-direction: column;
}

.character-customizeable-options > label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
}

.character-customizeable-options > .name {
  font-weight: bold;
  border-bottom: 2px solid #5648c7;
  background: #5648c7;
  color: #FFF;
  padding: 6px 10px;
}

.character-customizeable-options > .section-title {
  background: #5648c7;
  color: #FFF;
  padding-left: 6px;
  border-radius: 4px;
  margin: 6px 0px;
}

.character-customizeable-options > label > *:first-child {
  margin-right: 8px;
}

.character-customizeable-options input {
  border: 0px none transparent;
  padding: 8px;
  font-size: 14px;
  border-radius: 2px;
}

.character-customizeable-options select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  height: 30px;
  border-radius: 2px;
}

.character-customizeable-options a {
  text-decoration: none;
}

.character-customizeable-options .animation-play {
  margin-left: 16px;
  cursor: pointer;
}

.character-customizeable-options-container {
  overflow-y: auto;
  max-height: 400px;
  padding: 0 16px 0 0;
}

.clone-btn {
  line-height: 16px;
  margin: 4px 8px;
  padding: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  width: 100px;
  color: white;
  background-color: #7465e4;
  border-bottom: 4px solid #5648c7;
  cursor: pointer;
}

.clone-btn:hover {
  border-bottom: 0px none transparent;
}

.layered-link {
  flex-grow: 1;
}

.pdn-link {
  min-width: 55px;
}

.character-properties {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: #5648c7;
  display: flex;
  padding: 0 16px;
  color: white;
  font-size: 16px;
  align-items: center;
  z-index: 2;
}

.character-properties > span {
  height: 24px;
  font-size: 24px;
  margin: 0 64px 0 0;
}

.character-properties a {
  color: white;
}

.facial-expression, .action, .skin, .disclaimer {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.zoom, .merc-ears {
  display: flex;
  align-items: center;
}

.margin-top-10 {
  margin-top: 10px;
}

.btn-large {
  padding: 10px 12px;
  width: 100%;
}
.pet-customizeable-options {
  display: flex;
  flex-direction: column;
}

.pet-customizeable-options > label {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.pet-customizeable-options > label > *:first-child {
  margin-right: 8px;
}

.pet-customizeable-options select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  border-radius: 2px;
}

.pet-customizeable-options a {
  text-decoration: none;
}

.pet-customizeable-options > div {
  margin-bottom: 0.5rem;
}

.npc-customizeable-options {
  display: flex;
  flex-direction: column;
}

.npc-customizeable-options > label {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.npc-customizeable-options > label > *:first-child {
  margin-right: 8px;
}

.npc-customizeable-options select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  border-radius: 2px;
  max-width: 10rem;
}

.npc-customizeable-options a {
  text-decoration: none;
}

.npc-customizeable-options > div {
  margin-bottom: 0.5rem;
}

.mob-customizeable-options {
  display: flex;
  flex-direction: column;
}

.mob-customizeable-options > label {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.mob-customizeable-options > label > *:first-child {
  margin-right: 8px;
}

.mob-customizeable-options select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  border-radius: 2px;
  max-width: 10rem;
}

.mob-customizeable-options a {
  text-decoration: none;
}

.mob-customizeable-options > div {
  margin-bottom: 0.5rem;
}

.canvas-bg:not(.dragging) {
  -webkit-transition: background-position-x 0.5s, background-position-y 0.5s;
  transition: background-position-x 0.5s, background-position-y 0.5s;
}

.canvas-bg, .bg-color-picker {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAACgCAMAAACsXRuGAAAADFBMVEWIiIjd3d3y8vLm5uZzdc63AAAAbklEQVR42u3ZIQ4AIAwDwI39/8/wAlTFxFUgm5ALmFZV51Iv52Vyx/7CzhdONr3/ylCgQIECBQoUKFCgQIECBQoUKFCgQIECBcrmccGe4qX4vqBAgQIFChQoUKBAgQIFChQoUKBAgQIFij3lX3gBXBBzc8FaGvIAAAAASUVORK5CYII=);
}

.renderables-container {
  position: relative;
}

.renderables-container:not(.dragging) {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.renderables-container > * {
  position: absolute;
}

.canvas-characters svg {
  pointer-events: none;
}

.canvas-coordinates {
    position: absolute;
    right: 0.5rem;
    top: 3.5rem;
    font-weight: bold;
}

.selected-canvas-element {
  -webkit-filter: drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.6));
}
.character-container > *{
  position: absolute;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
  overflow: hidden;
}

.renderable-instance {
  cursor: all-scroll;
}

