.add-possible {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.add-title {
  border-bottom: 2px solid rgba(0,0,0,0.2);
}

.add-possible li {
  padding: 4px;
  user-select: none;
}

.add-possible li.clickable {
  cursor: pointer;
  border-radius: 4px;
}

.add-possible li.clickable:hover {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
}

.character-list {
    position: fixed;
    right: 0;
    bottom: 0px;
    height: 100px;
    width: 100%;
    background: rgba(255,255,255,0.75);
    z-index: 2;
    box-shadow: 0 -1px 1px 1px rgba(0,0,0,0.14);
}

.character-list > div {
  width: 100%;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  height: 100px;
}

.character-list > div > div {
  width: 100px;
  height: 100px;
}

.character-list div.character,
.character-list div.pet,
.character-list div.npc,
.character-list div.mob,
.character-list .add {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.character-list .add span {
  font-size: 42px;
  width: 70px;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  line-height: 65px; /* It looks off center if the line height is 70px :( */
}

.character-list .add:hover span {
  background: rgba(0,0,0, 0.1);
}

.character-list div.character:not(:hover) > * {
    display: none;
}

.character-list div.active {
  box-shadow: inset 0 0 32px rgba(86, 72, 199, 0.25);
}

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #128D15;
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #19AB27;
  }

  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;
  }

  .bold {
    font-weight: bold;
  }
