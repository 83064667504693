.item-listing {
  position: absolute;
  left: 0;
  top: 48px;
  bottom: 100px;
  max-width: 285px;
  width: 100%;
  background: #FFF;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.3);
  z-index: 2;
}

.item-listing-header {
  font-size: 18px;
  line-height: 36px;
  min-height: 36px;
  padding: 0 4px 0 8px;
  display: flex;
  justify-content: space-between;
  color: #3F3F3F;
}

.item-listing-header input[type=search] {
  align-self: center;
}

.item-listing-content {
  display: flex;
  flex-grow: 1;
  max-height: calc(100% - 36px);
  flex-direction: column;
}

.item-listing-categories {
  display: flex;
  flex-direction: column;
  border-top: solid 1px white;
  background-color: #5648c7;
  padding: 0.5rem 0 0;
}

.item-listing-categories select {
  border: 0px;
  font-size: 14px;
  line-height: 28px;
  min-height: 28px;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  padding: 0 4px;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0 0.5rem 0.5rem;
}

.item-listing-icons {
  flex: 1;
}

.similar-items {
  position: absolute;
  z-index: 3;
  padding: 4px;
  background: #eee;
  padding: 5px;
  box-shadow: 0 0 1px #999;
  border-radius: 5px;
  cursor: pointer;
}

.similar-items div {
  display: inline-block;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
  margin: 0 2px;
  padding: 0.25rem;
  border-radius: 0.25rem;
  transition: all 300ms;
}

.similar-items div:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.item-img-container {
  display: inline-block;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
}

.item-img-container img, .similar-items img {
  max-width: 32px;
  overflow: hidden;
}

.item-search {
  border: 0;
  font-size: 14px;
  line-height: 28px;
  min-height: 28px;
  border-radius: 0.25rem;
  padding: 0 8px;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.filters {
  font-size: 14px;
  line-height: 36px;
  min-height: 36px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
}

.filters label {
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  font-weight: 700;
}

.gender-select {
  border: 0px;
  font-size: 14px;
  line-height: 28px;
  min-height: 28px;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  padding: 0 4px;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.9);
  width: 5rem;
}

.items-footer {
  display: flex;
  align-items: center;
  background-color: #5648c7;
  color: white;
  justify-content: space-between;
}

.items-footer label {
    display: flex;
    margin: 0 0.5rem 0 0.25rem;
}

.item-img-container {
  cursor: pointer;
  padding: 0.25rem;
  text-align: center;
  transition: all 300ms;
}

.item-img-container:hover {
  background-color: #e2e2e2;
  border-radius: 0.25rem;
  transform: translateY(-2px);
}
