.character-customizeable-options {
  display: flex;
  flex-direction: column;
}

.character-customizeable-options > label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
}

.character-customizeable-options > .name {
  font-weight: bold;
  border-bottom: 2px solid #5648c7;
  background: #5648c7;
  color: #FFF;
  padding: 6px 10px;
}

.character-customizeable-options > .section-title {
  background: #5648c7;
  color: #FFF;
  padding-left: 6px;
  border-radius: 4px;
  margin: 6px 0px;
}

.character-customizeable-options > label > *:first-child {
  margin-right: 8px;
}

.character-customizeable-options input {
  border: 0px none transparent;
  padding: 8px;
  font-size: 14px;
  border-radius: 2px;
}

.character-customizeable-options select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  height: 30px;
  border-radius: 2px;
}

.character-customizeable-options a {
  text-decoration: none;
}

.character-customizeable-options .animation-play {
  margin-left: 16px;
  cursor: pointer;
}

.character-customizeable-options-container {
  overflow-y: auto;
  max-height: 400px;
  padding: 0 16px 0 0;
}

.clone-btn {
  line-height: 16px;
  margin: 4px 8px;
  padding: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  width: 100px;
  color: white;
  background-color: #7465e4;
  border-bottom: 4px solid #5648c7;
  cursor: pointer;
}

.clone-btn:hover {
  border-bottom: 0px none transparent;
}

.layered-link {
  flex-grow: 1;
}

.pdn-link {
  min-width: 55px;
}

.character-properties {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  background: #5648c7;
  display: flex;
  padding: 0 16px;
  color: white;
  font-size: 16px;
  align-items: center;
  z-index: 2;
}

.character-properties > span {
  height: 24px;
  font-size: 24px;
  margin: 0 64px 0 0;
}

.character-properties a {
  color: white;
}

.facial-expression, .action, .skin, .disclaimer {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.zoom, .merc-ears {
  display: flex;
  align-items: center;
}

.margin-top-10 {
  margin-top: 10px;
}

.btn-large {
  padding: 10px 12px;
  width: 100%;
}