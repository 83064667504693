.equipped-items {
  position: fixed;
  right: 0.5rem;
  bottom: 115px;
  width: 15%;
  background: rgba(255, 255, 255, 0.75);
  min-width: 255px;
  overflow: auto;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
}

.equipped-items a {
  text-decoration: none;
}

.equipped-items-header {
  min-height: 36px;
  font-size: 18px;
  line-height: 36px;
  padding: 0 16px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  color: #3F3F3F;
  display: flex;
  justify-content: space-between;
}

.equipped-items-title {
  font-weight: bold;
}

.equipped-items-header .equipped-items-title {
  align-self: center;
}

.equipped-items-listing {
  flex-grow: 1;
  max-height: 40vh;
  overflow: auto;
}

.download-bar {
  text-align: right;
}

.report-issues, .download-bar {
  display: flex;
  padding: 8px;
}

.download-bar.bg-blue:hover {
  opacity: 0.8;
  background: #42a5f5;
}

.download-bar.bg-red:hover {
  opacity: 0.8;
  background: #fb6d6d;
}

.equipped-items-item {
  display: flex;
  padding: 8px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  position: relative;
  overflow: visible;
}

.equipped-items-item .hue-picker {
  position: absolute!important;
  bottom: -6px;
  left: 0;
  right: 0;
}

.equipped-items-item .hue-picker:not(:focus) {
  display: none;
}

.equipped-items-item:hover .hue-picker {
  display: block;
}

.equipped-items-item:hover {
  background: #F5F5F5;
}

.equipped-items-item img {
  align-self: center;
  min-width: auto;
  max-width: 32px;
  max-height: 32px;
  overflow: hidden;
}

.equipped-items-item-meta {
  padding: 0 0 0 8px;
  flex-grow: 1;
}

.equipped-items-item-meta-category {
  font-size: 12px;
}

.pdn-button .download-bar, .pdn-button {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.customizing-item {
  width: 220px;
  position: relative;
  max-height: 40vh;
  overflow: auto;
  padding: 10px;
}

.customizing-item {
  display: flex;
  flex-direction: column;
}

.customizing-item > label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.customizing-item > span {
  text-align: left;
  display: inline-block;
  width: 100%;
  line-height: 24px;
}

.customizing-item > span > span {
  margin-left: 4px;
  font-size: 16px;
}

.customizing-item > span > span.flex {
  justify-content: space-between;
}

.customizing-item > span > span > input {
  margin-left: 32px;
  display: flex;
  max-width: 50%;
  text-align: right;
}

.item-property {
  margin: 8px 0;
}

.item-islot, .item-vslot {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}