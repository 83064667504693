body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
  overflow: hidden;
}

.renderable-instance {
  cursor: all-scroll;
}
