.npc-customizeable-options {
  display: flex;
  flex-direction: column;
}

.npc-customizeable-options > label {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.npc-customizeable-options > label > *:first-child {
  margin-right: 8px;
}

.npc-customizeable-options select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  border-radius: 2px;
  max-width: 10rem;
}

.npc-customizeable-options a {
  text-decoration: none;
}

.npc-customizeable-options > div {
  margin-bottom: 0.5rem;
}
