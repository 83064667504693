.App {
  font-family: Muli,sans-serif;
  width: 100%;
  bottom: 0px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background: #5648c7 url('/kino.png') 16px 0 no-repeat;
  background-size: contain;
  height: 48px;
  font-size: 24px;
  color: white;
  display: flex;
  position: relative;
  z-index: 9999;
  flex-wrap: wrap;
}

.App-header .logo {
    padding: 8px 0 0px 70px;
    display: inline-block;
}

.App-header .logo a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.App-header .alpha {
  background: white;
  padding: 2px 3px;
  border-radius: 3px;
  font-size: 12px;
  color: #5648c7;
  margin-left: 10px;
}

.App-header .desc {
  font-size: 14px;
  display: block;
}

.App-header .Nav-right li:hover {
  color: #FFF;
  text-decoration: none;
  background: rgba(255,255,255,0.1);
}

.App-header .Nav-right li a {
  padding: 19px 12px;
  display: inline-block;
  font-size: 20px;
  float: left;
}

.App-header .Nav-right li a {
  color: #FFF;
  text-decoration: none;
}

.App-intro {
  font-size: large;
}

.tools a {
  display: inline-block;
  justify-content: center;
  padding: 4px;
  margin: 8px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.1);
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Generic Styles */
.right {
  float: right;
}
.left {
  float: left;
}

a {
  color: #5648c7;
}

.btn {
  padding: 4px 8px;
  transition: all 0.1s ease;
  box-shadow: inset 0 1px 0 rgba(248, 249, 250, 0.0125), 0 1px 1px rgba(33, 37, 41, 0.05);
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 1px 1px 3px 1px;
  font-size: 14px;
  display: inline-block;
  line-height: initial;
  align-self: center;
  text-decoration: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.btn:hover {
  border-width: 1px 1px 1px 1px;
  margin-bottom: 2px;
  transform: translateY(2px);
}

.bg-red {
  background-color: #fa5252;
}

.bg-blue {
  background-color: #42a5f5;
}

.bg-green {
  background-color: #37a228;
}

.bg-purple {
  background-color: #7465e4;
}

.text-white {
  color: #FFF;
}

body > div:not(.tippy-popper){ /* kms, this freaking rule. :< */
  z-index: 10000;
}

.tippy-popper input {
  border: 0px none transparent;
  font-size: 18px;
  display: -ms-flexbox;
  display: flex;
  color: #3F3F3F;
  font-family: Muli,sans-serif;
}

.canvas-characters, .canvas-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App .notification-container {
  right: calc(1em + 30px);
  overflow: visible;
  width: 230px;
}

.App .notification {
  padding: 15px 8px;
}

.notification-container .notification:before {
  content: ' ';
  border-top: calc(1em + 5.5px) solid transparent;
  border-bottom: calc(1em + 5.5px) solid transparent;
  border-right: calc(1em + 5.5px) solid white;
  width: 0;
  height: 0;
  position: absolute;
  left: calc(-1em - 5.5px);
  top: 15px;
}

.notification-container .notification:after {
  content: ' ';
  border-top: calc(1em + 5.5px) solid transparent;
  border-bottom: calc(1em + 5.5px) solid transparent;
  border-left: calc(1em + 5.5px) solid white;
  width: 0;
  height: 0;
  position: absolute;
  right: calc(-1em - 5.5px);
  top: 1px;
  font-size: 28px;
}

.notification-container .notification.notification-warning:after, .notification-container .notification.notification-warning:before {
  border-left-color: #f89406;
  border-right-color: #f89406;
}

.notification-container .notification.notification-error:after, .notification-container .notification.notification-error:before {
  border-left-color: #bd362f;
  border-right-color: #bd362f;
}

.map-select-container {
  width: 374px;
  padding: 13px 0;
  font-size: 16px;
  position: relative;
  z-index: 10001;
}

.map-select-container .VirtualizedSelectOption {
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 35px;
  padding: 0 4px;
  cursor: pointer;
}

.map-select-container .VirtualizedSelectOption:hover {
  background: #eee;
}

.bg-color-picker {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
}

.bg-color-grid {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.bg-color-picker-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bg-color-picker-container .sketch-picker {
  position: absolute;
  top: 44px;
  left: 8px;
  z-index: 10002;
}

.settings-container {
  display: flex;
  flex-direction: column;
}

.settings-container > label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.settings-container > label > *:first-child {
  margin-right: 8px;
}

.settings-container input {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  font-size: 14px;
  border-radius: 2px;
}

.settings-container select {
  border: 0px none transparent;
  box-shadow: 0 0 2px rgb(72, 138, 199);
  padding: 4px;
  border-radius: 2px;
}

.settings-container a {
  text-decoration: none;
}

.settings-container .animation-play {
  margin-left: 16px;
  cursor: pointer;
}

.settings-cog {
  width: 48px;
}

.settings-cog > div {
  width: 100%;
  height: 100%;
  display: flex!important;
  justify-content: center;
  align-items: center;
}

.map-select-container .Select-menu {
  max-height: 398px;
}

.map-select-container .Select-menu-outer {
  max-height: 400px;
}

.App-header .Nav-right li a.patreon-link {
  color: hotpink;
  font-weight: 800;
  text-shadow: 0 0 4px red;
}

@media (max-width: 767px) {
  .App-header {
    justify-content: flex-end;
  }

  .App-header .logo {
    display: none;
  }

  .App-header .Nav-right {
    align-self: center;
    display: inline-flex;
    list-style: none;
    height: 100%;
    margin: 0;
  }
  
  .App-header .Nav-right li {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .App-header {
    justify-content: space-between;
  }

  .App-header .logo {
    display: inline;
  }

  .App-header .Nav-right {
    align-self: center;
    padding: 0px 10px 0px 0;
    display: inline-flex;
    list-style: none;
    height: 100%;
    margin: 0;
  }
  
  .App-header .Nav-right li {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 0 8px;
  }
}